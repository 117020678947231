<template>
  <div>
    <gmaps-map
      :options="mapOptions"
      class="myMaps"
    >
      <gmaps-info-window :options="options">
        <p><b class="brand">mille et 1 pains</b><br>155 Avenue Ambroise Croizat, 38400 Saint-Martin-d'Hères <br> tel: 04 76 54 11 59</p>
      </gmaps-info-window>
    </gmaps-map>
  </div>
</template>
<script>
import { gmapsMap, gmapsInfoWindow } from 'x5-gmaps'

export default {
  name: 'Maps',
  components: { gmapsMap, gmapsInfoWindow },
  data: () => ({
    options: {
      position: { lat: 45.18253892005783, lng: 5.759880567085927 },
      zoom: 14
    },
    mapOptions: {
      center: { lat: 45.18253892005783, lng: 5.759880567085927 },
      zoom: 17,
      fullscreenControl: false,
      mapTypeControl: false,
      rotateControl: false,
      scaleControl: false,
      streetViewControl: false,
      zoomControl: false
    }
  })
}
</script>
<style lang="scss" scoped>
.myMaps {
  position:absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
}
.brand {
  font-size: 2em;
  text-transform: uppercase;
  font-family: 'Amatic SC', cursive;
}
</style>
